export const LANGUAGES = [
	{
		id: "1",
		locale: "de",
		displayName: "Deutsch",
	},
	{
		id: "2",
		locale: "en",
		displayName: "English",
	},
	{
		id: "3",
		locale: "es",
		displayName: "Español",
	},
	{
		id: "4",
		locale: "fr",
		displayName: "Français",
	},
	{
		id: "5",
		locale: "it",
		displayName: "Italiano",
	},
	{
		id: "6",
		locale: "se",
		displayName: "Svenska",
	},
];

export const SORTS = [
	{
		id: "1",
		name: "-createdAt",
		displayName: "Latest",
	},
	{
		id: "2",
		name: "createdAt",
		displayName: "Oldest",
	},
	{
		id: "3",
		name: "title",
		displayName: "A-Z",
	},
	{
		id: "4",
		name: "-title",
		displayName: "Z-A",
	},
];

export const LIST_DEFAULT_SORT = "-createdAt";
export const LIST_DEFAULT_LANGUAGE = "";
export const LIST_DEFAULT_TAG = "";
export const RESULT_PER_PAGE = 10;
