import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Background from "./Background";
import Landing from "./Landing";
import CreateDictionary from "./components/create/createDictionary.jsx";
import ListDictionary from "./components/list/listDictionary.jsx";
import FindDictionary from "./components/view/findDictionary.jsx";
import ShowDictionary from "./components/view/showDictionary.jsx";
import "./scss/default-styles.scss";

export default function App() {
	return (
		<React.Fragment>
			<Background />
			<Router>
				<Route exact path='/' component={Landing} />
				<Route path='/create' component={CreateDictionary} />
				<Route path='/list' component={ListDictionary} />
				<Route exact path='/view' component={FindDictionary} />
				<Route exact path='/view/:id' component={ShowDictionary} />
			</Router>
		</React.Fragment>
	);
}

