import React, { Component } from "react";
import PropTypes from "prop-types";
import { PictoInput, ValidationMessage } from "../common";

export default class DictionaryName extends Component {
	constructor(props) {
		super(props);
		this.state = {
			validationMessage: "",
		};
	}

	handleChange = event => {
		this.props.onNameChange(event.target.value);
	};

	validateName = () => {
		if (!this.props.name) {
			this.props.setNameValid(false);
			this.setState({
				validationMessage: "The name needs to be at least 3 characters long",
			});
			return false;
		} else if (this.props.name.length < 3) {
			this.props.setNameValid(false);
			this.setState({
				validationMessage: "The name needs to be at least 3 characters long",
			});
			return false;
		} else if (this.props.name.length > 30) {
			this.props.setNameValid(false);
			this.setState({
				validationMessage: "The name cannot exceed 30 characters",
			});
			return false;
		}
		this.props.setNameValid(true);
		this.setState({
			validationMessage: "",
		});
		return true;
	};

	getInputClassName() {
		return (
			"picto-input" +
			(this.state.validationMessage.length === 0 && this.props.name.length > 0
				? "  picto-input--ok"
				: "")
		);
	}

	render() {
		return (
			<PictoInput
				title='*Enter the name of the dictionary:'
				inputField={
					<input
						id='name-input'
						maxLength='30'
						required
						value={this.props.name}
						onChange={this.handleChange}
						onBlur={this.validateName}
						className={this.getInputClassName()}
						placeholder='Dictionary name...'
					/>
				}
			>
				<ValidationMessage
					key={0}
					message={this.state.validationMessage}
				></ValidationMessage>
			</PictoInput>
		);
	}
}

DictionaryName.propTypes = {
	name: PropTypes.string.isRequired,
	onNameChange: PropTypes.func.isRequired,
};
