import React, { Component } from "react";
import PropTypes from "prop-types";
import qrcode from "qrcode-generator";

export default class QrCode extends Component {
	constructor(props) {
		super(props);
		this.state = {
			svg: { __html: null },
		};
	}
	generateQRCode = id => {
		const qr = qrcode(0, "M");
		qr.addData(id);
		qr.make();
		this.setState(() => {
			return { svg: { __html: qr.createSvgTag() } };
		});
	};

	componentDidMount() {
		this.generateQRCode(this.props.id);
	}

	render() {
		return (
			<div className='picto-component-container'>
				<div className='picto-component-inner picto-input-container picto-qr-container'>
					<div
						id='qrcode'
						className='picto-qr-svg-container'
						dangerouslySetInnerHTML={this.state.svg}
					></div>
				</div>
			</div>
		);
	}
}

QrCode.propTypes = {
	id: PropTypes.number.isRequired,
};
