import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import DictionarySign from "./assets/images/dict_sign.png";
import InputSign from "./assets/images/input_sign.png";
import ListSign from "./assets/images/list_sign.png";

export default function Landing() {
	return (
		<React.Fragment>
			<div id='landing-dictionary' className='picto-main-container'>
				<BigButton
					image={DictionarySign}
					link='/create'
					nameParagraph={
						<p>
							Create <br /> dictionary
						</p>
					}
				></BigButton>
				<BigButton
					image={ListSign}
					link='/list'
					nameParagraph={
						<p>
							List
							<br /> dictionaries
						</p>
					}
				></BigButton>
				<BigButton
					image={InputSign}
					link='/view'
					nameParagraph={
						<p>
							View
							<br /> dictionary
						</p>
					}
				></BigButton>
			</div>
		</React.Fragment>
	);
}

const BigButton = props => {
	return (
		<div className='picto-component-container'>
			<Link to={props.link} className='picto-button-big'>
				<div className='picto-component-inner create-dictionary-container'>
					{props.nameParagraph}
					<img src={props.image} alt='create dictionary' />
				</div>
			</Link>
		</div>
	);
};

BigButton.propTypes = {
	image: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
	nameParagraph: PropTypes.object.isRequired,
};
