import React, { Component } from "react";
import { Link } from "react-router-dom";
import FilterDictionary from "./filterDictionary";
import { fetch } from "whatwg-fetch";
import queryString from "query-string";

export default class ListDictionary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filters: {},
			dictionaries: [],
			totalCount: 0,
		};
	}

	handleFilterChange = filters => {
		this.setState({ filters, previousCursors: [], nextCursor: null }, () => {
			this.loadDictionaries();
		});
	};

	loadDictionaries() {
		const self = this;
		let url = "//" + process.env.API_URL + "/dictionaries";
		url += "?" + queryString.stringify(this.state.filters);
		fetch(url, {
			method: "GET",
		})
			.then(response => {
				const totalCount = parseInt(response.headers.get("x-total-count"));
				self.setState({ totalCount });
				return response.json();
			})
			.then(json => {
				self.setState({
					dictionaries: json,
				});
			})
			.catch(ex => {
				console.log("Exception: " + ex);
				self.setState({ totalCount: 0 });
			});
	}

	renderNoResults = () => {
		return (
			this.state.dictionaries.length === 0 && (
				<div className='picto-dictionary-container'>
					<span className='picto-dictionary-container-info'>No Results</span>
				</div>
			)
		);
	};

	renderDictionaries = () => {
		return this.state.dictionaries.map((d, i) => {
			return (
				<div key={i} className='picto-dictionary-container'>
					<Link to={"/view/" + d.id} style={{ textDecoration: "none" }}>
						<div className='picto-button picto-button--clickable picto-button--dictionary'>
							{d.title} {d.creator_name && <span>(by {d.creator_name})</span>}
						</div>
					</Link>
				</div>
			);
		});
	};

	render() {
		return (
			<div className='picto-main-container'>
				<FilterDictionary
					onFilterChange={this.handleFilterChange}
					totalCount={this.state.totalCount}
				>
					<div className='picto-component-container'>
						<div id='view-dictionary-box' className='picto-dictionary-box'>
							<div className='picto-dictionary-box--flex'>
								{this.renderNoResults()}
								{this.renderDictionaries()}
							</div>
						</div>
					</div>
				</FilterDictionary>
			</div>
		);
	}
}
