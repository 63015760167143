import React, { Component } from "react";
import QrCode from "./qrCode";
import { Link } from "react-router-dom";
import { PictoContainer, ValidationMessage } from "../common.jsx";

export default class ShowDictionary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: this.props.match.params.id,
			dictionary: null,
			showWords: false,
			showOrHideButton: "Show words",
			error: false,
		};
	}

	componentDidMount() {
		this.getDictionary();
	}

	getDictionary = () => {
		if (!this.state.id) {
			this.setState({
				error: true,
			});
			return;
		}
		fetch("//" + process.env.API_URL + "/dictionaries/" + this.state.id, {
			method: "GET",
		})
			.then(res => res.json())
			.then(res => {
				this.setState({ dictionary: res });
			})
			.catch(ex => {
				this.setState({
					error: true,
				});
			});
	};

	toggleWords = () => {
		this.setState(prevState => {
			return {
				showOrHideButton: prevState.showWords ? "Show words" : "Hide words",
				showWords: !prevState.showWords,
			};
		});
	};

	renderCreator = () => {
		return (
			this.state.dictionary.creator_name && (
				<p className='picto-creator'>
					Created by: {this.state.dictionary.creator_name}
				</p>
			)
		);
	};

	renderTag = () => {
		return (
			this.state.dictionary.tag && (
				<p className='picto-creator'>Tag: {this.state.dictionary.tag} </p>
			)
		);
	};

	renderWords = () => {
		return (
			this.state.showWords && (
				<div id='view-word-box' className='picto-word-box'>
					<div className='picto-word-box--flex'>
						{this.state.dictionary.words.map(wordItem => (
							<div key={wordItem} className='picto-word-container'>
								<div className='picto-button picto-button--word'>
									{wordItem}
								</div>
							</div>
						))}
					</div>
				</div>
			)
		);
	};

	render() {
		return (
			(this.state.dictionary && (
				<div id='view-dictionary' className='picto-main-container'>
					<div className='picto-component-container'>
						<div className='picto-component-inner'>
							<p className='picto-name'>{this.state.dictionary.title}</p>
							{this.renderCreator()}
							{this.renderTag()}
						</div>
					</div>
					<div className='picto-component-container'>
						<button
							className='picto-button picto-button--clickable picto-show-hide-words-button'
							onClick={this.toggleWords}
						>
							{this.state.showOrHideButton}
						</button>
						{this.renderWords()}
					</div>
					<div className='picto-component-container'>
						<div className='picto-component-inner'>
							<p className='picto-component-title picto-id-title'>
								Dictionary ID:
							</p>
							<div className='picto-input-container'>
								<input className='picto-input' readOnly value={this.state.id} />
							</div>
						</div>
					</div>
					<QrCode id={this.state.id}></QrCode>
					<p className='picto-creator'>
						Click <a href='/create'>Here</a> to create a Dictionary
					</p>
				</div>
			)) ||
			(this.state.error && (
				<div className='picto-main-container'>
					<PictoContainer>
						<ValidationMessage message="Couldn't find a dictionary with that id."></ValidationMessage>
						<Link to='/view'>
							<button className='picto-button picto-button--clickable'>
								Try again
							</button>
						</Link>
					</PictoContainer>
				</div>
			))
		);
	}
}
