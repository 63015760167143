import React, { Component } from "react";
import PropTypes from "prop-types";
import {
	ValidationMessage,
	PictoContainer,
	PictoComponentTitle,
	PictoInput,
} from "../common.jsx";

export default class DictionaryWords extends Component {
	constructor(props) {
		super(props);
		this.state = {
			wordInput: "",
			validationMessage: "",
		};
	}

	handleInputChanged = event => {
		this.setState({ wordInput: event.target.value });
	};

	handleKeyPress = event => {
		if (event.key === "Enter") {
			this.addWord(event);
		}
	};

	addWord = event => {
		event.preventDefault();
		const { wordInput } = this.state;

		if (!wordInput || wordInput.length < 1) {
			this.setState({
				validationMessage: "The word needs to be at least 1 characters long",
			});
			return;
		}
		if (this.props.words.indexOf(wordInput) === -1) {
			let wordList = this.props.words.slice();
			wordList.push(wordInput);
			this.setState({ validationMessage: "", wordInput: "" });
			this.props.onWordsChange(wordList);
			this.props.setWordsValid(true);
			this.textInput.focus();
		} else {
			this.setState({ validationMessage: `You can't add the same word twice` });
		}
	};

	removeWord = word => {
		let wordsList = this.props.words.slice();
		wordsList.splice(wordsList.indexOf(word), 1);
		this.props.onWordsChange(wordsList);

		if (this.props.words.length === 0) {
			this.props.setWordsValid(false);
		}
	};

	renderWordItems = () => {
		return this.props.words.map(word => (
			<div key={word} className='picto-word-container'>
				<div className='picto-button picto-button--word'>{word}</div>
				<button onClick={this.removeWord} className='picto-delete-word'>
					X
				</button>
			</div>
		));
	};

	render() {
		return (
			<PictoContainer>
				<PictoComponentTitle key={0} title='*Add words:'></PictoComponentTitle>
				<div key={1} className='picto-word-box'>
					<div className='picto-word-box--flex' name='word'>
						{this.renderWordItems()}
					</div>
					<PictoInput
						title=''
						ignoreContainer={true}
						inputField={
							<React.Fragment>
								<input
									ref={input => {
										this.textInput = input;
									}}
									className='picto-input'
									id='word-input'
									type='text'
									value={this.state.wordInput}
									onChange={this.handleInputChanged}
									onKeyPress={this.handleKeyPress}
									placeholder='New word...'
									style={{ minWidth: "auto", flexGrow: "2" }}
								/>
								<button
									onClick={this.addWord}
									className='picto-button picto-button--clickable picto-add-word picto-word-container'
								>
									+
								</button>
							</React.Fragment>
						}
					>
						<ValidationMessage
							key={0}
							message={this.state.validationMessage}
						></ValidationMessage>
					</PictoInput>
				</div>
			</PictoContainer>
		);
	}
}

DictionaryWords.propTypes = {
	words: PropTypes.array.isRequired,
	onWordsChange: PropTypes.func.isRequired,
	setWordsValid: PropTypes.func.isRequired,
};
