import React, { Component } from "react";
import PropTypes from "prop-types";
import { PictoInput, PictoContainer, ErrorMessage } from "../common.jsx";
import { LANGUAGES } from "../../constants.js";
import DictionaryName from "./dictionaryName.jsx";
import DictionaryWords from "./dictionaryWords.jsx";
// import DictionaryEmail from "./dictionaryEmail.jsx";
import { fetch } from "whatwg-fetch";
import { Redirect } from "react-router-dom";

export default class CreateDictionary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dicName: "",
			dicCreator: "",
			dicLanguage: "en",
			dicEmails: [],
			dicWords: [],
			dicTag: "",
			validName: false,
			validWordlist: false,
			validLanguage: true,
			validEmail: true,
			response: undefined,
			sending: false,
			error: false,
			errorMessage: "",
		};
	}

	handleInputEvent = event => {
		const { name, value } = event.target;
		this.setState({ [name]: value });
	};

	handleNameChange = name => {
		this.setState({ dicName: name });
	};

	handleEmailsChange = emails => {
		this.setState({ dicEmails: emails });
	};

	handleWordsChange = words => {
		this.setState({ dicWords: words });
	};

	setNameValid = valid => {
		this.setState({ validName: valid });
	};

	setWordsValid = valid => {
		this.setState({ validWordlist: valid });
	};

	valid = () => {
		if (!this.state.validName) {
			this.setState({
				errorMessage: "Dictionary name is invalid",
				error: true,
			});
			return false;
		}
		if (!this.state.validWordlist) {
			this.setState({ errorMessage: "Dictionary needs words", error: true });
			return false;
		}
		this.setState({ errorMessage: "", error: false });
		return true;
	};

	createDictionary = () => {
		if (!this.valid()) return;

		let data = {
			title: this.state.dicName,
			words: this.state.dicWords,
			locale: this.state.dicLanguage,
		};

		if (this.state.dicEmails.length > 0) {
			data.emails = this.state.dicEmails;
		}

		if (this.state.dicCreator) {
			data.creator_name = this.state.dicCreator;
		}

		if (this.state.dicTag) {
			data.tag = this.state.dicTag;
		}
		this.setState({ sending: true });
		fetch("//" + process.env.API_URL + "/dictionaries", {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then(res => res.json())
			.then(response => this.setState({ response: response, sending: false }))
			.catch(error => {
				console.error("Error:", error);
				this.setState({ sending: false });
			});
	};

	renderRedirect() {
		return (
			this.state.response && (
				<Redirect push to={`/view/${this.state.response}`} />
			)
		);
	}

	render() {
		return (
			<div className='picto-main-container'>
				<DictionaryName
					name={this.state.dicName}
					onNameChange={this.handleNameChange}
					setNameValid={this.setNameValid}
				/>
				<DictionaryCreator
					creator={this.state.dicCreator}
					onCreatorChange={this.handleInputEvent}
				/>
				<DictionaryTag
					tag={this.state.dicTag}
					onTagChange={this.handleInputEvent}
				/>
				<DictionaryWords
					words={this.state.dicWords}
					onWordsChange={this.handleWordsChange}
					setWordsValid={this.setWordsValid}
				/>
				<DictionaryLanguage
					language={this.state.dicLanguage}
					onLanguageChange={this.handleInputEvent}
				/>
				{/* <DictionaryEmail
					emails={this.state.dicEmails}
					onEmailsChange={this.handleEmailsChange}
				/> */}
				<ErrorMessage
					error={this.state.error}
					message={this.state.errorMessage}
				></ErrorMessage>
				<PictoContainer>
					<button
						key={0}
						className='picto-button picto-button--clickable'
						onClick={this.createDictionary}
						disabled={this.state.sending}
					>
						CREATE!
					</button>
				</PictoContainer>
				{this.renderRedirect()}
			</div>
		);
	}
}

const DictionaryCreator = props => {
	return (
		<PictoInput
			title='Your name:'
			inputField={
				<input
					name='dicCreator'
					className='picto-input'
					maxLength='40'
					value={props.creator}
					onChange={props.onCreatorChange}
					placeholder='Your Name...'
				/>
			}
		></PictoInput>
	);
};

DictionaryCreator.propTypes = {
	creator: PropTypes.string.isRequired,
	onCreatorChange: PropTypes.func.isRequired,
};

const DictionaryTag = props => {
	return (
		<PictoInput
			title='Tag:'
			inputField={
				<input
					name='dicTag'
					style={{ textTransform: "uppercase" }}
					className='picto-input'
					maxLength='40'
					value={props.tag}
					placeholder='Dictionary tag...'
					onChange={props.onTagChange}
				/>
			}
		></PictoInput>
	);
};

DictionaryTag.propTypes = {
	tag: PropTypes.string.isRequired,
	onTagChange: PropTypes.func.isRequired,
};

const DictionaryLanguage = props => {
	let optionItems = LANGUAGES.map(language => (
		<option key={language.id} value={language.locale}>
			{language.displayName}
		</option>
	));
	return (
		<PictoInput
			title='*Select language:'
			inputField={
				<select
					name='dicLanguage'
					className='picto-input'
					value={props.language}
					onChange={props.onLanguageChange}
				>
					{optionItems}
				</select>
			}
		></PictoInput>
	);
};

DictionaryLanguage.propTypes = {
	language: PropTypes.string.isRequired,
	onLanguageChange: PropTypes.func.isRequired,
};
