import React from "react";
import PropTypes from "prop-types";

const PictoContainer = props => {
	return <div className='picto-component-container'>{props.children}</div>;
};

const PictoContainerInner = props => {
	return props.standalone ? (
		<div className='picto-component-inner'>{props.children}</div>
	) : (
		<div className='picto-component-container'>
			<div className='picto-component-inner'>{props.children}</div>
		</div>
	);
};

PictoContainerInner.propTypes = {
	standalone: PropTypes.bool,
};

const PictoInput = props => {
	return (
		<PictoContainerInner standalone={props.ignoreContainer}>
			<PictoComponentTitle
				title={props.title}
				centered={props.titleCentered}
			></PictoComponentTitle>
			<span>{props.description}</span>
			<div className='picto-input-container'>{props.inputField}</div>
			{props.children}
		</PictoContainerInner>
	);
};

PictoInput.propTypes = {
	inputField: PropTypes.object.isRequired,
	title: PropTypes.string.isRequired,
	titleCentered: PropTypes.bool,
	description: PropTypes.string,
	ignoreContainer: PropTypes.bool,
};

const PictoComponentTitle = props => {
	const style = props.centered ? { textAlign: "center" } : {};
	return (
		<p className='picto-component-title' style={style}>
			{props.title}
		</p>
	);
};

PictoComponentTitle.propTypes = {
	title: PropTypes.string.isRequired,
	centered: PropTypes.bool,
};

const ValidationMessage = props => {
	return <p className='picto-input-hint'>{props.message}</p>;
};

ValidationMessage.propTypes = {
	message: PropTypes.string.isRequired,
};

const ErrorMessage = props => {
	const cName =
		"picto-component-container picto-error-container" + props.error
			? ""
			: " picto-error-hidden";
	return (
		<div className={cName}>
			<div className='picto-error-text'>{props.message}</div>
		</div>
	);
};

ErrorMessage.propTypes = {
	message: PropTypes.string.isRequired,
	error: PropTypes.bool.isRequired,
};

export {
	PictoContainer,
	PictoContainerInner,
	PictoComponentTitle,
	PictoInput,
	ValidationMessage,
	ErrorMessage,
};
