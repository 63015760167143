import React from "react";
import BackgroundImage from "./assets/images/background_ground.png";
import Road from "./assets/images/road.png";
import Trees from "./assets/images/trees.png";
import CloudSmall from "./assets/images/cloud-small.png";
import CloudMedium from "./assets/images/cloud-medium.png";
import CloudBig from "./assets/images/cloud-big.png";
import BalloonOne from "./assets/images/balloon-1.png";
import BalloonTwo from "./assets/images/balloon-2.png";
import PictoLogoDroop from "./assets/images/logo_droop.png";
import PictoLogoDots from "./assets/images/logo_dots.png";
import PictoLogoImg from "./assets/images/logo.png";

export default function Background() {
	return (
		<React.Fragment>
			<img
				className='picto-background picto-background-background'
				src={BackgroundImage}
				alt='background'
			/>
			<img
				className='picto-background picto-background-road'
				src={Road}
				alt='road'
			/>
			<img
				className='picto-background picto-background-trees'
				src={Trees}
				alt='trees'
			/>
			<img
				className='picto-background picto-background-cloud picto-background-cloud-small'
				src={CloudSmall}
				alt='small cloud'
			/>
			<img
				className='picto-background picto-background-cloud picto-background-cloud-medium'
				src={CloudMedium}
				alt='medium cloud'
			/>
			<img
				className='picto-background picto-background-cloud picto-background-cloud-big'
				src={CloudBig}
				alt='bid cloud'
			/>
			<img
				className='picto-background picto-background-balloon picto-background-balloon-1'
				src={BalloonOne}
				alt='balloon'
			/>
			<img
				className='picto-background picto-background-balloon picto-background-balloon-2'
				src={BalloonTwo}
				alt='balloon'
			/>
			<div id='app' className='picto-main-container'>
				<div className='picto-logo-container'>
					<img
						className='picto-logo-droop'
						src={PictoLogoDroop}
						alt='logo droop'
					/>
					<img
						className='picto-logo-dots'
						src={PictoLogoDots}
						alt='logo dots'
					/>
					<a href='/'>
						<div className='picto-logo'>
							<img className='picto-logo-img' src={PictoLogoImg} alt='logo' />
						</div>
					</a>
				</div>
			</div>
		</React.Fragment>
	);
}
