import React, { Component } from "react";
import queryString from "query-string";
import {
	LIST_DEFAULT_TAG,
	LIST_DEFAULT_LANGUAGE,
	LIST_DEFAULT_SORT,
	LANGUAGES,
	SORTS,
	RESULT_PER_PAGE,
} from "../../constants.js";
import { debounce } from "../../utils";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

const langs = [
	[
		<option key={0} value=''>
			All
		</option>,
	],
	...LANGUAGES.map(language => (
		<option key={language.id} value={language.locale}>
			{language.displayName}
		</option>
	)),
];

const sortOptions = SORTS.map(sort => (
	<option key={sort.id} value={sort.name}>
		{sort.displayName}
	</option>
));

class FilterDictionary extends Component {
	constructor(props) {
		super(props);
		const query = queryString.parse(this.props.location.search);
		this.state = {
			tag: query.tag || LIST_DEFAULT_TAG,
			sort: query.sort || LIST_DEFAULT_SORT,
			locale: query.language || LIST_DEFAULT_LANGUAGE,
			page: parseInt(query.page) || 1,
		};
	}

	componentDidMount() {
		this.props.onFilterChange(this.state);
	}

	componentDidUpdate(prevProps) {
		if (this.props.location.search !== prevProps.location.search) {
			const newState = queryString.parse(this.props.location.search);
			newState.page = parseInt(newState.page);
			this.setState(newState, () => {
				this.props.onFilterChange(this.state);
			});
		}
	}

	handleFilterChange = event => {
		const { name, value } = event.target;
		this.setState({ [name]: value }, () => {
			this.pushState();
		});
	};

	handleNextPage = () => {
		if (!this.hasNextPage()) return;
		this.setState(
			prevState => {
				return { page: prevState.page + 1 };
			},
			() => this.pushState()
		);
	};

	handlePreviousPage = () => {
		if (!this.hasPreviousPage()) return;
		this.setState(
			prevState => {
				return { page: prevState.page - 1 };
			},
			() => this.pushState()
		);
	};

	hasNextPage = () => {
		return this.state.page * RESULT_PER_PAGE < this.props.totalCount;
	};

	hasPreviousPage = () => {
		return this.state.page > 1;
	};

	pushState = debounce(() => {
		this.props.history.push({
			search: queryString.stringify(this.state),
		});
	}, 500);

	render() {
		return (
			<React.Fragment>
				<div className='options-container'>
					<div className='filter-container' style={{ display: "inlineBlock" }}>
						<SortFilter
							sort={this.state.sort}
							onSortChange={this.handleFilterChange}
						/>
						<LanguageFilter
							language={this.state.locale}
							onLanguageChange={this.handleFilterChange}
						/>
					</div>
					<div className='tag-container'>
						<TagFilter
							tag={this.state.tag}
							onTagChange={this.handleFilterChange}
						/>
					</div>
				</div>
				{this.props.children}
				<Pagination
					page={this.state.page}
					onNext={this.handleNextPage}
					onPrevious={this.handlePreviousPage}
					hasNextPage={this.hasNextPage}
					hasPreviousPage={this.hasPreviousPage}
				></Pagination>
			</React.Fragment>
		);
	}
}

FilterDictionary.propTypes = {
	onFilterChange: PropTypes.func.isRequired,
	totalCount: PropTypes.number.isRequired,
};

const LanguageFilter = props => {
	return (
		<div id='list-filter-language' className='lang-container'>
			<div className='picto-component-inner' style={{ width: "100%" }}>
				<p className='picto-component-title'>Language:</p>
				<select
					name='locale'
					className='lang-input'
					value={props.language}
					onChange={props.onLanguageChange}
				>
					{langs}
				</select>
			</div>
		</div>
	);
};

LanguageFilter.propTypes = {
	language: PropTypes.string.isRequired,
	onLanguageChange: PropTypes.func.isRequired,
};

const SortFilter = props => {
	return (
		<div id='list-sort' className='sort-container'>
			<div className='picto-component-inner' style={{ width: "100%" }}>
				<p className='picto-component-title'>Sort:</p>
				<select
					name='sort'
					className='sort-input'
					value={props.sort}
					onChange={props.onSortChange}
				>
					{sortOptions}
				</select>
			</div>
		</div>
	);
};

SortFilter.propTypes = {
	sort: PropTypes.string.isRequired,
	onSortChange: PropTypes.func.isRequired,
};

const TagFilter = props => {
	return (
		<div id='list-filter-tag'>
			<div className='picto-component-inner' style={{ width: "100%" }}>
				<p className='picto-component-title'>Tag:</p>
				<div className='tag-input-container'>
					<input
						name='tag'
						className='tag-input'
						maxLength='40'
						placeholder='Dictionary tag...'
						value={props.tag}
						onChange={props.onTagChange}
					/>
				</div>
			</div>
		</div>
	);
};

TagFilter.propTypes = {
	tag: PropTypes.string.isRequired,
	onTagChange: PropTypes.func.isRequired,
};

const Pagination = props => {
	const nextClassName =
		"picto-button picto-button--dictionary arrow-button" +
		(!props.hasNextPage() ? " low-opacity" : "");
	const previousClassName =
		"picto-button picto-button--dictionary arrow-button" +
		(!props.hasPreviousPage() ? " low-opacity" : "");
	return (
		<div className='picto-component-container'>
			<div className={previousClassName} onClick={props.onPrevious}>
				<span>🠈</span>
			</div>
			<div className='picto-button current-page' style={{ margin: 8 }}>
				Page {props.page}
			</div>
			<div className={nextClassName} onClick={props.onNext}>
				<span>🠊</span>
			</div>
		</div>
	);
};

Pagination.propTypes = {
	onNext: PropTypes.func.isRequired,
	onPrevious: PropTypes.func.isRequired,
	hasNextPage: PropTypes.func.isRequired,
	hasPreviousPage: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
};

export default withRouter(FilterDictionary);
