import React, { Component } from "react";
import { fetch } from "whatwg-fetch";
import { Redirect } from "react-router-dom";
import {
	PictoInput,
	PictoContainerInner,
	ValidationMessage,
} from "../common.jsx";

class FindDictionary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: "",
			validId: false,
			error: false,
			errorMessage: "",
			response: null,
		};
	}

	handleIdChange = event => {
		event.preventDefault();
		this.setState({ id: event.target.value.toUpperCase() });
	};

	handleKeyPress = event => {
		if (event.key === "Enter") {
			this.getDictionary();
		}
	};

	getDictionary = () => {
		if (!this.state.id) {
			this.setState({
				error: true,
				errorMessage: "Please enter correct dictionary id",
				validId: false,
			});
			return;
		}
		let self = this;
		fetch("//" + process.env.API_URL + "/dictionary/" + this.state.id, {
			method: "GET",
		})
			.then(res => res.json())
			.then(res => {
				this.setState({ response: res });
			})
			.catch(ex => {
				self.setState({
					error: true,
					errorMessage: "Please enter correct dictionary id",
					validId: false,
				});
			});
	};

	render() {
		return (
			(!this.state.response && (
				<div className='picto-main-container'>
					<PictoInput
						title='View dictionary by id:'
						titleCentered={true}
						inputField={
							<React.Fragment>
								<input
									className='picto-input'
									placeholder='Enter dictionary id...'
									value={this.state.id}
									onChange={this.handleIdChange}
									onKeyPress={this.handleKeyPress}
								/>
							</React.Fragment>
						}
					></PictoInput>
					<PictoContainerInner>
						<button
							className='picto-button picto-button--clickable'
							onClick={this.getDictionary}
						>
							View dictionary
						</button>
					</PictoContainerInner>
					<ValidationMessage
						message={this.state.errorMessage}
					></ValidationMessage>
				</div>
			)) || <Redirect push to={"/view/" + this.state.id}></Redirect>
		);
	}
}

export default FindDictionary;
